import React, {FC, memo} from 'react';
import {IGridProps} from '@common/types';
import Picture from '@components/component/Picture';
import {EComponent} from '@redux/types';
import Text from '@components/Text';


const ToolsGrid: FC<IGridProps> = ({items}) => {

  return (
    <section className="inner-stack">
      <ul className="inner-stack__list">
        {items?.map((c, index) => {
          return (
            <li key={index} className="inner-stack__item">
              <Picture media={{__component: EComponent.MEDIA, image: c.image || null}}/>
              <span className="inner-stack__text"><Text text={c.title}/></span>
            </li>
          )
        })}
      </ul>
    </section>
  )
}

export default memo(ToolsGrid)
