import React, {FC, memo, useMemo} from 'react';
import {IBannerProps} from '@common/types';
import Picture from '@components/component/Picture';
import {EBlockPosition, EBlockType, EComponent} from '@redux/types';
import RichTextRender from '@components/component/RichTextRender';
import Text from '@components/Text';
import {isNotEmptyRichContent} from '@common/functions';


const Banner: FC<IBannerProps> = ({banner}) => {

  const emptyText = useMemo(() => !isNotEmptyRichContent(banner?.text), [banner])

  let classNames = ['inner-image-text', banner?.type !== 'block-10' ? '' : 'inner-image-text--grid inner-image-text--margin-top-40',
    banner?.placement?.position === EBlockPosition.outsideStep && banner.placement?.type !== EBlockType.sticky ? 'inner-image-text--no-margin' : '',
    `inner-image-text--${banner.type ?? 'block-10'}`]
  let imagesClassNames = ['inner-image-text__image']
  let textClassNames = [
    'inner-simple-text',
    (!emptyText ? 'inner-simple-text--font-40-20-20' : 'inner-simple-text--big inner-simple-text--font-40-35-20')
    //banner?.titleParams?.classNames?.map(c => `inner-simple-text${c}`).join(' ')
  ]
  let smallTextClassNames = [
    'inner-simple-text',
    'inner-simple-text--small',
    'inner-simple-text--margin-top-40-20',
    'inner-simple-text--font-20-15-15'
  ]
    //banner?.descriptionParams?.classNames?.map(c => `inner-simple-text${c}`).join(' ')


  if (banner.type === 'block-picture') {
    return (
      <div className="picture-text">
        <div className="picture-text__wrapper">
          <div className="inner-picture picture-text__picture">
            <Picture
              media={{image: banner.backgroundImage.data?.attributes || null, imageMobile: banner.imageMobile?.data?.attributes || null, __component: EComponent.MEDIA}}
              withoutWrapper={true}
            />
          </div>
          {(!emptyText || banner?.title) && (
            <div className="picture-text__text">
              <h2 className="picture-text__title"><Text text={banner?.title}/></h2>
              <RichTextRender content={banner?.text} classes={"picture-text__texts"}/>
            </div>
          )}
        </div>
        <p className="picture-text__description">
          <Text text={banner.backgroundImage.data?.attributes?.caption}/></p>
      </div>
    )
  }

  return (
    <div className={classNames.join(' ')}>
      <div className="inner-image-text__content">
        <div className={imagesClassNames.join(' ')}>
          <Picture
            media={{image: banner.backgroundImage.data?.attributes || null, imageMobile: banner.imageMobile?.data?.attributes || null, __component: EComponent.MEDIA}}
            withoutWrapper={true}
          />
        </div>
        <div className="inner-image-text__description">
          <div className={textClassNames.join(' ')}>
            <p><Text text={banner?.title}/></p>
          </div>
          {!emptyText && (
            <div className={smallTextClassNames.join(' ')}>
              <RichTextRender content={banner?.text}/>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default memo(Banner)
