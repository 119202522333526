import React, {FC, memo} from 'react';
import {IQuoteProps} from '@common/types';
import Picture from '@components/component/Picture';
import {EBlockPosition, EBlockType, EComponent} from '@redux/types';
import RichTextRender from '@components/component/RichTextRender';
import Icon from '@components/Icon';
import {useActions} from '@common/hooks/useActions';
import Text from '@components/Text';


const Quote: FC<IQuoteProps> = ({quote}) => {
  const actions = useActions()
  let classNames = ['inner-sticky-block__card', `inner-sticky-block__card--${quote.color ?? 'black'}`,
    `inner-sticky-block__card--${quote.height ?? 'mh-default'}`]

  let wrapperClassNames = ['inner-sticky-block__content', quote?.placement?.position === EBlockPosition.outsideStep ? (quote?.placement?.type === EBlockType.sticky ? '' : 'inner-sticky-block__content--no-margin') :
    (quote?.placement?.type === EBlockType.simple ? 'inner-sticky-block__content--margin-big' : '')]
  const video = quote?.person?.video?.data?.attributes
  const image = quote?.person?.image?.data?.attributes

  return (
    <div className={wrapperClassNames.join(' ')}>
      <h2 className="inner-sticky-block__title inner-sticky-block__title--hidden">Заголовок</h2>
      <div className="inner-sticky-block__aside aside">
        {(image && !video) && (
          <Picture media={{
            __component: EComponent.MEDIA,
            image: quote.person.image!!.data!!.attributes,
            imageMobile: quote.person.imageMobile?.data?.attributes || null
          }} wrapperClassName={'inner-sticky-block__picture'}/>
        )}
        {video && (
          <div className="inner-sticky-block__video">
            <div className="inner-sticky-block__video-picture">
              <Picture media={{__component: EComponent.MEDIA, image: image!!}} withoutWrapper={true}/>
            </div>
            <button
              className="review__play"
              onClick={() => actions.setApp({modalVideo: {video, open: true}})}
              data-review-video-link="v-3/video/video-review-geoscan.mp4"
            >
              <Icon name="icon-play" className="review__play-icon" width={50} height={58}/>
            </button>
            <div className="inner-sticky-block__video-author">
              {quote.person?.fullName && (
                <span className="inner-sticky-block__video-name">{quote.person?.fullName}</span>
              )}
              {video.caption && (
                <span className="inner-sticky-block__video-project"><Text text={video.caption}/></span>
              )}
            </div>
          </div>
        )}
      </div>
      <div className={classNames.join(' ')}>
        <div className="inner-sticky-block__text">
          <RichTextRender content={quote?.text}/>
        </div>
        <div className="inner-sticky-block__author">
          {video ? (
            <div className="inner-sticky-block__video">
              <div className="inner-sticky-block__video-picture">
                <Picture media={{__component: EComponent.MEDIA, image: image!!}} withoutWrapper={true}/>
              </div>
              <button
                className="inner-sticky-block__video-play"
                onClick={() => actions.setApp({modalVideo: {video, open: true}})}
                data-review-video-link="v-3/video/video-review-geoscan.mp4"
              >
                <Icon name="icon-play" className="inner-sticky-block__video-icon" width={18} height={18}/>
              </button>
              <div className="inner-sticky-block__video-author">
                {quote.person?.fullName && (
                  <span className="inner-sticky-block__video-name">{quote.person?.fullName}</span>
                )}
                {video.caption && (
                  <span className="inner-sticky-block__video-project"><Text text={video.caption}/></span>
                )}
              </div>
            </div>
          ) : (
            <>
              {!!quote?.person?.image?.data?.attributes && (
                <Picture media={{
                  __component: EComponent.MEDIA,
                  image: quote.person.image.data!!.attributes,
                  imageMobile: quote.person.imageMobile?.data?.attributes || null
                }} wrapperClassName={'inner-sticky-block__picture'}/>
              )}
            </>
          )}

          <div className="inner-sticky-block__author-info">
            <p>{quote.person?.fullName}</p>
            <p>{quote.person?.position}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(Quote)
