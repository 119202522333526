import {ReactNode, useMemo} from 'react';
import {isNotEmptyRichContent} from '@common/functions';
import {EBlockPosition, IStepComponent, TComponent} from '@redux/types';
import {useTypedSelector} from '@common/hooks/useTypedSelector';
import {EPage} from '@common/types';

interface IStepHeaderClasses {
  mainClass: string,
  marginClass?: string,
  emptyMarginClass?: string
  mobileNormalTitleClass?: string,
  mobileSmallTitleClass?: string
}

export const useStepHeaderSettings: (props: {step: IStepComponent, stepChildren: TComponent[], children?: ReactNode, classes: IStepHeaderClasses
}) => any = ({ step, stepChildren, children, classes }) => {
  const {page} = useTypedSelector(state => state.app)

  const emptyText = useMemo(() => !isNotEmptyRichContent(step?.description), [step])
  const emptyNote = useMemo(() => !isNotEmptyRichContent(step?.note), [step])
  const stepDescriptionInsideStepCount = useMemo(() => stepChildren?.filter(stepChild => (
    stepChild?.placement?.position === EBlockPosition.insideStepDescription))?.length || 0, [step])

  const headerClasses = useMemo(() => [classes.mainClass,
    (!emptyText || !!stepDescriptionInsideStepCount || children) ? classes?.marginClass || '' : classes?.emptyMarginClass || '',
    page && [EPage.PROJECT_PAGE, EPage.VACANCY_PAGE, EPage.VACANCIES].includes(page) ? classes?.mobileSmallTitleClass || '' : classes?.mobileNormalTitleClass || ''
  ], [page, emptyText, stepDescriptionInsideStepCount, children])

  return {
    emptyText, emptyNote, stepDescriptionInsideStepCount, headerClasses
  }
}
