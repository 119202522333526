import React, {FC, useEffect, useState} from 'react';
import {IAccordionProps} from '@common/types';
import Icon from '@components/Icon';
import RichTextRender from '@components/component/RichTextRender';
import {IAccordionItem} from '@redux/types';
import {Accordions} from '@common/utils/accordions';


const Accordion: FC<IAccordionProps> = ({ items }) => {

  const [localItems, setLocalItems] = useState<IAccordionItem[]>(items)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const acc = new Accordions()
      acc?._init()
    }
  }, [])

  useEffect(() => {
    setLocalItems(items)
  }, [items])

  return (
    <ul className="accordion" data-accordion="parent" data-single>
      {localItems?.map((item) => (
        <li className={`accordion__element ${!item?.opened ? '' : 'is-active'}`} data-accordion="element" key={item.id}>
          <button className="btn btn--white accordion__button" type="button" data-accordion="button">
            <RichTextRender content={item?.title}/>
            <Icon width={13} height={8} name="icon-arrow"/>
          </button>
          <div className="accordion__content" data-accordion="content">
            <div className="accordion__wrapper">
              <div className="inner-simple-text">
                <RichTextRender content={item?.text}/>
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul>
  )
}

export default Accordion
