import React, {FC, memo} from 'react';
import {IGridProps, ITranslation} from '@common/types';
import Link from 'next/link';
import {useTranslation} from '@common/hooks/useTranslation';
import {EBlockPosition, EBlockType} from '@redux/types';
import RichTextRender from '@components/component/RichTextRender';
import Text from '@components/Text';


const SimpleGrid: FC<IGridProps> = ({items, height, parent, type, position}) => {
  const t = useTranslation('components') as ITranslation['components']

  const classPositionNames = position !== EBlockPosition.insideStepDescription ? '' : ' inner-simple-cards--half'

  const classNames = type !== EBlockType.sticky && position === EBlockPosition.outsideStep ? ' inner-simple-cards--no-margin'
    : type === EBlockType.simple ? ' inner-simple-cards--margin-big' : type !== EBlockType.sticky ? '' : ' inner-simple-cards--margin-small'

  return (
    <ul className={`inner-simple-cards${classPositionNames}${classNames} inner-simple-cards--text-bottom inner-simple-cards--${height ?? 'height-125'} inner-simple-cards--mobile-margin-middle`}>
      {items?.map((c, index) => (
        <li key={`card_${c.title}_${index}`} className="inner-simple-cards__item">
          <h3 className="inner-simple-cards__heading">
            <Text text={c.title}/></h3>
          {c?.text && (
            <RichTextRender classes={'inner-simple-cards__description'} content={c?.text} parent={parent}/>
          )}
          {c.url && (
            <p className="inner-simple-cards__description">
              {c.url && (
                <Link className="inner-simple-cards__link" href={c.url} aria-label={t.grid.card.linkAriaLabel}>
                  {c.urlTitle ?? c.url}
                </Link>
              )}
            </p>
          )}
        </li>
      ))}
    </ul>
  )
}

export default memo(SimpleGrid)
