import React, {FC, memo} from 'react';
import {ITableProps} from '@common/types';
import {isNotEmptyRichContent} from '@common/functions';
import RichTextRender from '@components/component/RichTextRender';
import Text from '@components/Text';
import {EBlockPosition} from '@redux/types';

const Table: FC<ITableProps> = ({ items, position }) => {
  const classPositionNames = position !== EBlockPosition.insideStepDescription ?
    (position !== EBlockPosition.insideStep ? '' : ' mt-40 mt-md-40 mt-sm-20') : ' mt-20 mt-md-20 mt-sm-10'
  return (
    <ul className={`inner-table__list ${classPositionNames}`}>
      {items?.map((item, index) => {

        const emptyText = !isNotEmptyRichContent(item?.text)

        return (
          <li key={index} className="inner-table__item">
            <span className="inner-table__key"><Text text={item.title}/></span>
            {(!emptyText || item?.description) && (
              <>
                {emptyText ? (<p className="inner-table__value"><Text text={item.description}/></p>) : (
                  <div className={"inner-table__value"}>
                    <RichTextRender content={item?.text || []}/>
                  </div>
                )}
              </>
            )}
          </li>
        )
      })}
    </ul>
  )
}

export default memo(Table)
