export class Accordions {
  _openHeight: number
  _windowWidth: number

  constructor() {
    this._openHeight = 0;
    this._windowWidth = window.innerWidth;
    this._documentClickHandler = this._documentClickHandler.bind(this);
    this._windowResizeHandler = this._windowResizeHandler.bind(this);
    this._init();
  }

  _init() {
    this.fullUpdate(null);
    document.addEventListener('click', this._documentClickHandler);
    window.addEventListener('resize', this._windowResizeHandler);
  }

  _documentClickHandler(evt: any) {
    const target = evt.target;
    if (!target.closest('[data-accordion="button"]')) {
      return;
    }

    //debugger;
    evt.preventDefault();
    const parent = target.closest('[data-accordion="parent"]');

    if (parent.dataset.destroy && !window.matchMedia(parent.dataset.destroy).matches) {
      return;
    }

    const element = target.closest('[data-accordion="element"]');
    if (element.classList.contains('is-active')) {
      this.closeAccordion(element);
      return;
    }
    this.openAccordion(element);
  }

  _windowResizeHandler() {
    if (this._windowWidth === window.innerWidth) {
      return;
    }
    this._windowWidth = window.innerWidth;
    this.updateAccordionsHeight(null);
  }

  closeAllAccordion(parent: Element) {
    const elements = parent.querySelectorAll('[data-accordion="element"]');
    elements.forEach((element: Element) => {
      const currentParent = element.closest('[data-accordion="parent"]');
      if (currentParent === parent) {
        this.closeAccordion(element);
      }
    });
  }

  updateAccordionsHeight(element: Element | null) {
    if (element) {
      const content = element.querySelector('[data-accordion="content"]') as HTMLDivElement | null;
      if (content) {
        content.style.transition = 'none';
        content.style.maxHeight = `${content.scrollHeight}px`;
        setTimeout(() => {
          content.style.transition = '';
        });
      }
      return;
    }

    const closeElements = document.querySelectorAll('[data-accordion="element"]:not(.is-active)');

    closeElements.forEach((closeElement) => {
      const parent = closeElement.closest('[data-accordion="parent"]') as HTMLDivElement | null;
      const content = closeElement.querySelector('[data-accordion="content"]') as HTMLDivElement | null;
      if (parent && parent?.dataset.destroy && !window.matchMedia(parent?.dataset.destroy).matches && content) {
        content.style.maxHeight = '100%';
        return;
      }
      if (content) {
        content.style.maxHeight ='0';
      }
    });

    const openElements = document.querySelectorAll('[data-accordion="element"].is-active');
    openElements.forEach((openElement) => {
      const content = openElement.querySelector('[data-accordion="content"]') as HTMLDivElement | null;
      const parent = openElement.closest('[data-accordion="parent"]') as HTMLDivElement | null;
      if (parent && parent.dataset.destroy && !window.matchMedia(parent.dataset.destroy).matches && content) {
        content.style.maxHeight = '100%';
        return;
      }
      if (content) {
        content.style.transition = 'none';
        content.style.maxHeight = `${content.scrollHeight}px`;
        setTimeout(() => {
          content.style.transition = '';
        });
      }
    });
  }

  fullUpdate(parent: HTMLDivElement | null, transition = false) {
    let openElements;
    if (parent) {
      openElements = parent.querySelectorAll('[data-accordion="element"].is-active');
    } else {
      openElements = document.querySelectorAll('[data-accordion="element"].is-active');
    }
    openElements.forEach((openElement) => {
      const innerParent = openElement.querySelector('[data-accordion="parent"]');
      if (innerParent) {
        return;
      }
      this.openAccordion(openElement, transition);
    });
    this.updateAccordionsHeight(null);
  }

  openAccordion(element: Element | null, transition = true) {
    if (element) {
      const parentElement = element.closest('[data-accordion="parent"]');
      const contentElement = element.querySelector('[data-accordion="content"]') as HTMLDivElement | null;
      this._openHeight += contentElement?.scrollHeight || 0;

      if (parentElement?.hasAttribute('data-single')) {
        this.closeAllAccordion(parentElement);
      }

      element.classList.add('is-active');
      if (contentElement) {
        if (transition) {
          contentElement.style.maxHeight = `${this._openHeight}px`;
        } else {
          contentElement.style.transition = 'none';
          contentElement.style.maxHeight = `${this._openHeight}px`;
          setTimeout(() => {
            contentElement.style.transition = '';
          });
        }
      }

      if (parentElement?.closest('[data-accordion="element"]')) {
        this.openAccordion(parentElement.closest('[data-accordion="element"]'), transition);
        return;
      }

      this._openHeight = 0;
    }
  }

  closeAccordion(element: Element | null, transition = true) {
    if (element) {
      const contentElement = element.querySelector('[data-accordion="content"]') as HTMLDivElement | null;
      if (!contentElement) {
        return;
      }
      element.classList.remove('is-active');
      if (transition) {
        contentElement.style.maxHeight = '0';
      } else {
        contentElement.style.transition = 'none';
        contentElement.style.maxHeight = '0';
        setTimeout(() => {
          contentElement.style.transition = '';
        });
      }
    }
  }
}
