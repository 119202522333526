import React, {FC, memo} from 'react';
import {IGridProps} from '@common/types';
import ToolsGrid from '@components/component/Grids/ToolsGrid';
import SimpleGrid from '@components/component/Grids/SimpleGrid';
import {EBlockType} from '@redux/types';
import ProductsGrid from '@components/component/Grids/ProductsGrid';


const Grid: FC<IGridProps> = ({items, height, type, parent, position}) => {
  const isCardImage = items?.some(i => !!i.image?.url)

  if (parent?.type === EBlockType.tools) {
    return <ToolsGrid items={items} position={position} />
  }

  return isCardImage ? (
    <ProductsGrid items={items} type={type} position={position}/>
  ) : (
    <SimpleGrid items={items} height={height} type={type} position={position} parent={parent}/>
  )
}

export default memo(Grid)
