import React, {FC, memo} from 'react';
import {EPage, IListProps} from '@common/types';
import SimpleTags from '@components/component/Tags/SimpleTags';
import {EBlockPosition, EBlockType} from '@redux/types';
import RichTextRender from '@components/component/RichTextRender';
import Text from '@components/Text';
import {useTypedSelector} from '@common/hooks/useTypedSelector';

const List: FC<IListProps> = ({items, height, position, type}) => {

  const {page} = useTypedSelector(state => state.app)
 
  const defaultClassNames = [
    'inner-simple-table inner-simple-table--no-padding ', height === 'small' || !height ? '' : 'inner-simple-table--gap-20-0-0',
    `inner-simple-table--height-small`, height === 'big' || !height ? 'inner-simple-table--tablet-font-middle' : '',
    position === EBlockPosition.outsideStep && height !== 'small' ?
        'inner-simple-table--margin-top-big inner-simple-table--tablet-font-middle inner-simple-table--margin-10-10-5' :
    type === EBlockType.sticky ? 'inner-simple-table--margin-top-small inner-simple-table--margin-10-10-5' :
      type === EBlockType.simple ? 'inner-simple-table--margin-top-big inner-simple-table--margin-25-25-5' :
        type === EBlockType.grid ?  'inner-simple-table--margin-top-middle inner-simple-table--margin-25-25-5' :
          'inner-simple-table--margin-top-middle inner-simple-table--margin-10-10-5',
  ]

  const customClassNames = [
    'custom-table',
    position !== EBlockPosition.insideStep ? (position !== EBlockPosition.insideStepDescription ? '' : 'custom-table--margin-top-small')
      : type === EBlockType.sticky ? 'custom-table--margin-top-small' : (type === EBlockType.simple ?
      'custom-table--margin-top-large' : 'custom-table--margin-top-big'),
    height === 'small' || !height ? 'custom-table--gap-0-5' :  'custom-table--gap-25-20',
    position === EBlockPosition.insideStepDescription ? 'custom-table--column-ratio-1-3' : 'custom-table--column-ratio-1-2'
  ]

  const getTitleLayout = (title: string, customTitle: any[] | undefined) => 
    title ? <Text text={title}/> : <RichTextRender classes="mt-0" content={customTitle} />

  return !items?.some(item => item?.tags?.length) ? (
    <div className={defaultClassNames.join(' ')}>
      <ul className="inner-simple-table__list">
        {items?.map((l, index) => {
          const title = getTitleLayout(l.title, l.customTitle)
          return (
          <li key={`list_item_${l.title}_${index}`} className={page !== EPage.SOLUTION ? "inner-simple-table__item"
            : "inner-simple-table__item inner-simple-table__item--no-border"}>
            <span className="inner-simple-table__key">
              {title}
            </span>
            <div className="inner-simple-table__value">
              <RichTextRender content={l?.text}/>
            </div>
          </li>
        )})}
      </ul>
    </div>
  ) : (
    <ul className={customClassNames.join(' ')}>
      {items?.map((l, index) => {
        const title = getTitleLayout(l.title, l.customTitle)
        return (
        <li key={`list_item_${l.title}_${index}`} className="custom-table-item">
          <div className="custom-table-item__key">
            <p>{title}</p>
          </div>
          <div className="custom-table-item__value">
            {l?.text && (
              <div className={height === 'small' ? 'inner-simple-text inner-simple-text--font-small' : 'inner-simple-text'}>
                <RichTextRender content={l?.text}/>
              </div>
            )}
            {!!l?.tags?.length && (
              <SimpleTags tags={l.tags}/>
            )}
          </div>
        </li>
      )})}
    </ul>
  )
}

export default memo(List)
